import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Grid from "./grid"
import MultiLineText from "./MultiLineText"
import { getNewsUrl } from "../urls/newsUrls"
import Img from "gatsby-image"

const NewsItem = styled.li`
  list-style: none;
  margin: 0;
  margin-bottom: 1rem;
`

const NewsItemLink = styled(Link)`
  --padding: 0.6rem;
  color: inherit;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
  line-height: 1.2rem;
  padding: var(--padding);
  transition: box-shadow 0.3s;
  border-radius: 5px;
  background-color: white;
  transition: transform ease 0.2s;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &:hover,
  &:active {
    box-shadow: 2px 2px 8px 4px var(--grey);
    /*transform: scale(1.025);*/
  }
`

const NewsDate = styled.div`
  font-weight: 300;
`

const NewsTitle = styled.div`
  font-weight: 600;
`

const NewsAbstract = styled(MultiLineText)`
  flex-grow: 1;
`

const ReadMore = styled.span`
  color: var(--primary-color);
  border-color: var(--primary-color);
  border-width: 1px;
  border-style: solid;
  padding: 0.2rem 0.6rem;
  border-radius: 5px;
  display: inline-block;
  margin-top: 0.5rem;
  transition: background-color 0.4s ease-out;
  align-self: flex-start;

  ${NewsItemLink}:hover & {
    background-color: var(--primary-color);
    color: white;
  }
`
const NewsImage = styled(Img)`
  margin: 0 calc(-1 * var(--padding));
  margin-bottom: 0.5rem;
`

const NewsTeaser = ({ news, title }) => {
  if (!news || news.length === 0) {
    return null
  }

  return (
    <>
      <h2>{title}</h2>
      <Grid columns="repeat(auto-fill, minmax(220px, 1fr))" as="ul">
        {news.map(_ => {
          let image =
            _.mainImage && _.mainImage.asset && _.mainImage.asset.fluid
          if (image) {
            image.aspectRatio = 3
          }

          const hotspot = _.mainImage && _.mainImage.hotspot

          const objectPosition =
            hotspot && `${hotspot.x * 100}% ${hotspot.y * 100}%`

          return (
            <NewsItem key={_.id}>
              <NewsItemLink to={getNewsUrl(_)}>
                {image && (
                  <NewsImage
                    fluid={image}
                    imgStyle={{ objectPosition }}
                    objectPosition={objectPosition}
                  ></NewsImage>
                )}
                <NewsDate>{_.publishedAt}</NewsDate>
                <NewsTitle>{_.title}</NewsTitle>
                <NewsAbstract>{_.abstract}</NewsAbstract>
                <ReadMore>Mehr lesen</ReadMore>
              </NewsItemLink>
            </NewsItem>
          )
        })}
      </Grid>
    </>
  )
}

export { NewsTeaser }
