import React, { useState, useEffect } from "react"

import SEO from "../components/seo"
import { NewsTeaser } from "../components/newsTeaser"
import { format } from "date-fns"
import { EventsTeaser } from "../components/eventsTeasers"

const EventsOverview = ({}) => {
  const [events, setEvents] = useState()

  useEffect(() => {
    ;(async () => {
      const date = format(new Date(), "yyyy-MM-dd")
      const query = `* | [ _type == "event" && date > "${date}"] |  order(date)`
      const response = await fetch(
        `https://59662g5w.apicdn.sanity.io/v1/data/query/production?query=${encodeURIComponent(
          query
        )}`
      )
      const eventData = await response.json()
      if (eventData.result) {
        setEvents(eventData.result)
      }
    })()
  }, [])

  return (
    <>
      <SEO title="Termine" />
      <h1>Termine</h1>
      {events && <EventsTeaser events={events} />}
    </>
  )
}

export default EventsOverview
