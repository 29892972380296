import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { parse, format } from "date-fns"
import { de } from "date-fns/locale"

const EventList = styled.ul`
  margin: 0;
`

const EventItem = styled.li`
  list-style: none;
  margin: 5px 5px 5px 0;
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: var(--light-grey);
  &:last-child {
    border-bottom-width: 1px;
  };
  &:first-child {
    border-top-width: 0;
  }
`

const EventItemLink = styled(Link)`
  color: inherit;
  width: 100%;
  text-decoration: none;
  display: inline-block;
  padding: 0.4rem 0.4rem;
  font-size: 1rem;
  line-height: 1.2rem;
  font-weight: 300;

  transition: box-shadow 0.3s;

  &:hover,
  &:active {
    box-shadow: 2px 2px 8px 4px var(--grey);
  }
`
const EventTitle = styled.span`
  font-weight: 600;
`
const EventDate = styled.div`

`

const EventsTeaser = ({ events }) => {
  let lastMonth = undefined
  return (
    <>
      <h2>{}</h2>
      <EventList>
        {events.map(_ => {
          const date = _.date && parse(_.date, "yyyy-MM-dd", new Date())
          const month = date.getMonth()
          let formatedMonth = ""
          if (month !== lastMonth) {
            formatedMonth = format(date, "MMMM", { locale: de })
            lastMonth = month
          }
          let formattedDate = date
            ? format(date, "EEE dd. MMMM", {
                locale: de,
              })
            : ""

          if (_.dateUntil) {
            formattedDate += ` - ${format(
              parse(_.dateUntil, "yyyy-MM-dd", new Date()),
              "EEE dd. MMMM",
              {
                locale: de,
              }
            )}`
          }
          return (
              <EventItem key={_._id}>
                <EventItemLink to={_.slug && `/event/${_.slug.current}`}>
                  <EventDate>{formattedDate}</EventDate> 
                  <EventTitle>{_.title}</EventTitle>
                </EventItemLink>
              </EventItem>
          )
        })}
      </EventList>
    </>
  )
}

export { EventsTeaser }
